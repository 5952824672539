import React, { useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import PSModal from '../../components/modal';
import SyllabusForm from '../../components/forms/syllabusForm';
import Button from '../../components/buttons/linkButton';
import { Container, Col, Row } from 'react-bootstrap';
import Advisory from '../../assets/images/advisory.png';
import Navbar from '../../components/navbars/courseLandingNavbar';
import ReactGA from 'react-ga'
import courseData from '../../utils/course-data';
import Bullet from '../../assets/icons/bullet.png';

const CourseContainer = styled.div`
  display: block;
  height: 700px;
  background: none;
  background-image: url(${Advisory});
  background-size: cover;
  background-position: center center;
`

const HeroContainer = styled(Container)`
  padding-top: 250px;
  max-width: 1000px;
  font-family: sans-serif;

  .col-hero-text {
    padding-left: 40px;
    h1 {
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--white);
    }
    .subtext {
      color: var(--white);
    }
    .cta-syllabus {
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  } 
`

const CurriculumSection = styled.section`
  padding-bottom: 80px;
  background-color: var(--black);
  p {
    color: var(--white);
    padding-top: 20px;
    text-align: center;
  }
  h2 {
    margin-top: 40px;
    text-align: center;
    color: var(--white);
  }
  .btn-apply-curriculum {
    margin-top: 40px;
  }
`

const GetInvolvedSection = styled.section`
`

const AboutSection = styled.section`
  background-color: var(--blue);

  .overview-text {
    color: var(--white);
  }

  h2 {
    text-align: center;
    color: var(--white);
    padding-bottom: 10px;
    padding-top: 40px;
  } 

  .benefits {
    h2 {
      font-size: 30px;
    }
  }

  padding-bottom: 80px;

  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }

  p {
    text-align: center;
  }

  .btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .problem-subtitle {
    text-align: center;
    max-width: 580px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
    color: var(--white);
  }

  .problems {
    color: var(--white);
    background-color: #fff;
    border-color: #e8ebed;
    border: 1px solid #bbbecc;
    border-bottom: 0;
    box-shadow: -10px 10px var(--black);
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
    position: relative;
    max-width: 870px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .problem {
    border-color: #e8ebed;
    width: 100%;
    border-bottom: 1px solid #bbbecc;
    padding: 20px;
    position: relative;
    padding-left: 20px;

    p {
      padding-bottom: 0;
      margin: 0;
      font-size: 1em;
      background-position: 0 0;
      background-size: 15px 15px;
      line-height: 20px;
      min-height: 15px;
      text-align: left;
      background-image: url(${Bullet});
      background-repeat: no-repeat;
      padding: 0 0 0 40px;
    }

    &:nth-child(2n) {
      border-left: 1px solid #bbbecc;
    }

    @media screen and (max-width: 767px) {
      width: 100%;

      &:nth-child(2n) {
        border-left: none;
      }
    }
  }
`

const DatesRow = styled(Row)`
  margin-top: 40px;
  margin-bottom: 40px;

  .dates-container {
    background-color: var(--yellow);
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
    h2 {
      text-align: center;
      padding-top: 20px;
    }
  }
  .dates-table-container {
    padding: 20px 50px 0px 50px;
  }
  .dates-table-row {
    margin-bottom: 25px;
    margin-top: 35px;
  }
  .dates-start {
    h5 {
      margin-bottom: 5px;
    }
    p {
      text-align: left;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 14px;
      color: var(--black);
    }
  }
  .dates-length {
    p {
      text-align: center;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 18px;
      color: var(--black);

      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .dates-pace {
    p {
      text-align: left;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 14px;
      color: var(--black);
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .dates-cta-container {
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-top: 25px;
    }
  }
  .dates-cta {
    background: #fff;
    border: 2px solid #000;
    padding: 10px 10px;
    display: inline-block;
    text-align: center;
    color: #000;
    -webkit-text-decoration: none;
    text-decoration: none;
    box-shadow: #000 -10px 8px 0px;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    font-size: 14px;
    min-width: 150px;
    text-align: center;
    font-family: 'roboto-mono';
    margin-top: -10px;
    margin-bottom: 20px;
    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }
`;

const Index = ({location}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => setShow(true);

  const fireGTMEvent = () => {
    ReactGA.event({
      category: 'CTA - Button',
      action: 'Click',
      label: 'waitlist',
    })
  };

  const renderNextDepositDeadline = (course) => {
    return courseData.returnNextDepositDate(course.deposit_deadlines);
  };

  return (
    <Layout>

      <CourseContainer>
        <Navbar location={location}/>
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <SyllabusForm close={handleClose} />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col className='col-hero-text' md={12}>
              <h1>Parsity Advisory Board</h1>
              <p className='subtext'>Represent your company as a member of Parsity’s Advisory Board as we seek to develop and diversify the future of tech talent.</p>

              <a target='_blank' href='https://parsity.typeform.com/to/baWt9zXP'>
                <Button classToAdd='cta-syllabus' text={'Apply for Membership'} />
              </a>

            </Col>
          </Row>
        </HeroContainer>
      </CourseContainer>

      <AboutSection>
        <Container>
          <Row>
            <Col md={12}>
              <div className='overview'>
                <h2 className='features-title'>Overview</h2>
                <p className='overview-text'>Parsity’s Advisory Board is the best avenue for companies to build a relationship with Parsity. Advisory Board members do this by helping us shape our curriculum and investing in our students. In turn, Advisory Board Members gain unique access to our network of recent and experienced alumni to meet your hiring needs.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className='benefits'>
                <h2>Advisory Board Benefits</h2>
                <div className='problems'>
                  <div className='problem'>
                    <p>Opportunities to present job openings to our network of both Junior and Senior Developers</p>
                  </div>
                  <div className='problem'>
                    <p>Invites to (optional) biannual Advisory Board Member meetings, where you’ll advise Parsity staff on the future of our program and its curriculum</p>
                  </div>
                  <div className='problem'>
                    <p>Member only quarterly updates from Parsity on student and alumni progress</p>
                  </div>
                  <div className='problem'>
                    <p>Network with other tech leaders who are on a mission to develop and diversify the future of tech talent</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className='benefits'>
                <h2>Advisory Board Requirements</h2>
                <div className='problems'>
                  <div className='problem'>
                    <p>You may join as a representative of your company (and rotate employees as members), or as an indivdual - only companies that employ software engineers will be considered.</p>
                  </div>
                  <div className='problem'>
                    <p>At least 1 person form your company attend each virtual Demo Day (happens every ~3 to 4 months).</p>
                  </div>
                  <div className='problem'>
                    <p>At least 1 person from your company to meet with 2 graduates within 2 weeks of their Demo Day. This can be virtual or in-person. The expectation is that you might better understand Parsity as you help our graduates expand their network. These meetings are not necessarily intended for you to consider these graduates as potential hires.</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

        </Container>
      </AboutSection>

      <GetInvolvedSection>
        <Container>
          <DatesRow>
            <Col md={12}>
              <div className='dates-container'>
                <h2>Optional Ways to Get Invovled</h2>
                  <div className='dates-table-container'>
                    <Row className='dates-table-row'>
                      <Col md={4}>
                        <div className='dates-start'>
                          <h3>Mentoring</h3>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='dates-pace'>
                          <p>Each week, our students meet with experienced software engineers (mentors) for live, 1on1 video calls to help them as they learn to become developers. Mentors are paid contractors.</p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='dates-cta-container'>
                          <div>
                            <a target='_blank' href='https://parsity.typeform.com/to/MAP0Fxhc'>
                              <Button link={true} classToAdd='dates-cta' text={'Apply to Mentor'} />
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className='dates-table-row'>
                      <Col md={4}>
                        <div className='dates-start'>
                          <h3>Job Shadowing</h3>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='dates-pace'>
                          <p>Help our students learn what it’s like to work at a company like yours by having them shadow a developer at your company for a day. If you’re interested in allowing our students to job-shadow, fill out this form.</p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='dates-cta-container'>
                          <div>
                            <a href='https://parsity.typeform.com/to/eHtjcUTL' target='_blank'>
                              <Button link={true} classToAdd='dates-cta' text={'Job Shadow Interest Form'} />
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </DatesRow>
        </Container>
      </GetInvolvedSection>

      <CurriculumSection>
        <Container>
          <Row id='curriculum'>
            <Col md={12}>
              <h2>Apply for Advisory Board Membership</h2>
              <div className="text-center btn-apply-curriculum">
                <a target='_blank' href='https://parsity.typeform.com/to/baWt9zXP'>
                  <Button link={true} classToAdd='dates-cta' text={'Apply to Advisory Board'} />
                </a>
              </div>   
            </Col>
          </Row>
        </Container>
      </CurriculumSection>
    </Layout>
  )
}

export default Index
